import React from "react";
import { Container, Row, Col } from "reactstrap"
import dayjs from 'dayjs'
import Layout from "../components/Layout/Layout";
import Seo from "../utils/Seo";
import PageHeader from "../components/shared/PageHeader";
import styled from "styled-components";
import {media} from "../utils/Media";
import {graphql} from "gatsby";
import Button from "../components/shared/Button";

const PageSubHeading = styled.section`
  width: 100%;
  background: ${(props) => props.theme.colors.grey};
  position: relative;
  div {
    position: absolute;
    top: -3rem;
  }
  h1 {
    padding: 2rem 0;
    text-align: center;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.family.boldTall} !important;
    font-size: 3rem !important;
    @media ${media.md} {
      font-size: 4rem !important;
    }
  }
`;

const ContainerStyled = styled(Container)`
  max-width: 1300px;
  padding: 0 25px;
  width: 100%;
  position: relative;
  margin: 0 auto;
`

const Intro = styled.p`
  font-size: 1.5rem !important;
`

const Wrap = styled.div`
  background-color: white;
  box-shadow: 0px 6.38596px 27.14035px 0px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  display: flex;
  max-width: 640px;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;

  h3 {
    text-transform: uppercase;
    font-size: 2rem !important;

    @media ${media.lg} {
      font-size: 3rem !important;
    }
  }

  p {
    font-size: 1rem !important;

    @media ${media.lg} {
      font-size: 1.25rem !important;
    }

    &.smaller {
      font-size: .85rem !important;

      @media ${media.lg} {
        font-size: 1rem !important;
      }
    }
  }

  .button {
    width: auto;

    span {
      font-size: 1.5rem !important;
      @media ${media.lg} {
        font-size: 2rem !important;
      }
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
`

const Date = styled.div`
  width: 100%;
`

const UkTour = ({data}) => {
  console.log(data)
  
  return (
    <Layout>
      <Seo slug="/uk-tour" title="UK & Ireland 2024 Tour" description="The official website for Grease The Musical. The world's best-loved musical is back in London’s West End! Grittier and more electrifying than ever before. | UK & Ireland 2024 Tour"/>
      <PageHeader tour={true} />
      <PageSubHeading>
        <div id="tour-list"></div>
        <h1>UK & IRELAND 2024 TOUR</h1>

      </PageSubHeading>

      {/* <Intro className="text-center py-5">Casting to be announced, check individual box offices for details.</Intro> */}


      <ContainerStyled className="pb-5" >
        <Row>
          {data.tour.nodes?.map((tour) => {
            //* get todays date
            const today = dayjs()
            //* get last showing date and wrap it in dayjs func
            const lastDate = dayjs(tour.lastDate)
            //* if the last day is before todays date set false else set true
            const isStillRunning = lastDate.isBefore(today, 'day') ? false : true

            if (isStillRunning) {
              return(
                <Col key={tour.city} xs={12} md={6} className="pb-4">
                  <Wrap>
                    <Content>
                      <p className="mb-1"><strong>{tour.date}</strong></p>
                      <h3>{tour.city}</h3>
                      <p className="smaller m-0"><strong>{tour.theatre}</strong></p>
                    </Content>
                    <div>
                      {tour.link ? (
                        <Button 
                          color="secondary" 
                          href={tour.link}
                          target="_blank" 
                          rel="noopener" 
                          className="button"
                        >
                          <span>BOOK NOW</span>
                        </Button>
                      ) : (
                        <p><strong>COMING SOON</strong></p>
                      )}
                    </div>
                  </Wrap>
                </Col>
              )
            } else return null
          })}
        </Row>
      </ContainerStyled>
    </Layout>
  );
};


export default UkTour;

export const TicketsQuery = graphql`
  query TICKETQUERY {
    tour: allTourJson {
      nodes {
        city
        date
        theatre
        link
        lastDate
      }
    }
  }
`;
